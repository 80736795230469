import Siema from 'siema';

const sliderPresent = document.querySelector('.slider');
if (sliderPresent) {
  const mySiema = new Siema({
    selector: '.slider',
    perPage: {
      800: 2,
      1200: 3,
    },
  });
  document.querySelector('.prev').addEventListener('click', () => mySiema.prev());
  document.querySelector('.next').addEventListener('click', () => mySiema.next());

  // Get the modal
  const modal = document.getElementById('myModal');

  // Get the image and insert it inside the modal - use its "alt" text as a caption
  const elements = document.querySelectorAll('.gallery-show');
  const modalImg = document.getElementById('modal-image-content');
  const modalVideo = document.getElementById('modal-video-content');
  const captionText = document.getElementById('caption');

  const modalNext = document.querySelector('.modal-next');
  const modalPrev = document.querySelector('.modal-prev');

  elements.forEach((element) => {
    element.addEventListener('click', (e) => {
      modal.style.display = 'block';
      showInAppropriateTag(element, e.target.dataset.id)
    });
  });

  const showInAppropriateTag = (originElement, id) => {
    captionText.innerHTML = originElement.dataset.description;
    if (originElement.tagName === 'VIDEO') {
      modalImg.classList.add('hidden')
      modalVideo.classList.remove('hidden')

      modalVideo.src = originElement.src
      modalVideo.dataset.id = id;
      modalImg.dataset.id = id;
    } else {
      modalVideo.classList.add('hidden')
      modalImg.classList.remove('hidden')

      modalImg.src = originElement.src;
      modalVideo.dataset.id = id;
      modalImg.dataset.id = id;
    }
  }

  const nextImage = () => {
    // If we get to the last item, go back to beginning
    if ((parseInt(modalImg.dataset.id) + 1) > (elements.length - 1)) {
      showInAppropriateTag(elements[0], 0)
      // modalImg.src = elements[0].src;
      // captionText.innerHTML = elements[0].alt;
      // modalImg.dataset.id = 0;
    } else {
      // Else show the next item
      showInAppropriateTag(elements[parseInt(modalImg.dataset.id) + 1], parseInt(modalImg.dataset.id) + 1)
      // modalImg.src = elements[parseInt(modalImg.dataset.id) + 1].src;
      // captionText.innerHTML = elements[parseInt(modalImg.dataset.id) + 1].alt;
      // modalImg.dataset.id = parseInt(modalImg.dataset.id) + 1;
    }
  };

  const previousImage = () => {
    // If we get to the first item, go to the last one.
    // [].slice.call(img).pop() get the last item of the `img` nodelist
    if ((parseInt(modalImg.dataset.id) - 1) < 0) {
      const element = elements[[].slice.call(elements).pop().dataset.id]
      showInAppropriateTag(element, element.dataset.id)
      // modalImg.dataset.id = elements[[].slice.call(elements).pop().dataset.id].dataset.id;
      // modalImg.src = elements[[].slice.call(elements).pop().dataset.id].src;
      // captionText.innerHTML = elements[[].slice.call(elements).pop().dataset.id].alt;
    } else {
      // Else show the previous one
      showInAppropriateTag(elements[parseInt(modalImg.dataset.id) - 1], parseInt(modalImg.dataset.id) - 1)
      // modalImg.src = elements[parseInt(modalImg.dataset.id) - 1].src;
      // captionText.innerHTML = elements[parseInt(modalImg.dataset.id) - 1].alt;
      // modalImg.dataset.id = parseInt(modalImg.dataset.id) - 1;
    }
  };

  modalNext.addEventListener('click', nextImage);
  modalPrev.addEventListener('click', previousImage);

  document.addEventListener('keyup', (e) => {
    if (e.keyCode == '39') {
      nextImage();
    } else if (e.keyCode == '37') {
      previousImage();
    }
  });


  // Get the <span> element that closes the modal
  const span = document.getElementsByClassName('close')[0];

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = 'none';
  };

  const fakeModal = document.querySelector('.fake-modal');
  fakeModal.onclick = function () {
    modal.style.display = 'none';
  };
}
