window.initMap = () => {
  // Becraft coordinates
  const myLatLng = { lat: 50.448986, lng: 3.951139 };

  // Intialize the map
  const map = new google.maps.Map(document.getElementById('map'), {
    center: myLatLng,
    zoom: 15,
    disableDefaultUI: true,
  });

  // Place the marker
  const marker = new google.maps.Marker({
    position: myLatLng,
    map,
    title: 'BeCraft',
  });

  // Text of the info window
  const contentString = '<div id="content">'
                        + '<strong>BeCraft</strong><br>'
                        + 'Site des Anciens Abattoirs<br>'
                        + '17/02, Rue de la Trouille<br>'
                        + 'B-7000 Mons (Belgique)<br>'
                        + '</div>';

  // Initialize the info window
  const infowindow = new google.maps.InfoWindow({
    content: contentString,
  });

  // Show the info window when click on the marker
  marker.addListener('click', () => { infowindow.open(map, marker); });
};
