import Sortable from 'sortablejs'

const initSortable = () => {
	const el = document.getElementById('admin-image-grid');
	if (!el) return
	const sortable = Sortable.create(el, {
		handle:   ".sortable-handle",
		onEnd: e => {
			// console.log('Original', e.explicitOriginalTarget.closest('li'))
			// console.log('Moved', e.item)
			// const replacedImage = e.explicitOriginalTarget.closest('li')
			const idOrder = Sortable.create(el, { dataIdAttr: 'data-id' }).toArray()
			fetch(el.dataset.url, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Cache-Control': 'no-cache',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify({
					order: idOrder
				})
			})
		}
	});

	const dragEndCallback = event => {

	}
}

export { initSortable }
