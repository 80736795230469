const body         = document.querySelector('body')
const showMenu     = document.querySelector('nav');
const menuLogo     = document.querySelector('.menu');
const menuElements = document.querySelectorAll('.menu-element');

function toggleMenu(e) {
  if (showMenu.dataset.toggled === 'false') {
    e.preventDefault();
    showMenu.style.height = '100vh';
    menuElements.forEach((m) => {
      const elem = m;
      elem.style.display = 'block';
      setTimeout(() => { elem.style.opacity = '100'; }, 300);
    });
    showMenu.dataset.toggled = 'true';
    body.style.overflow = 'hidden';
  } else {
    e.preventDefault();
    showMenu.style.height = '80px';
    menuElements.forEach((m) => {
      const elem = m;
      elem.style.opacity = '0';
      elem.style.display = 'none';
    });
    showMenu.dataset.toggled = 'false';
    body.style.overflow = 'initial';
  }
}

function handleMenu() {
  if (window.matchMedia('(hover: none)').matches || window.matchMedia('(max-width: 950px)').matches) {
    menuLogo.addEventListener('click', toggleMenu);
  } else {
    menuLogo.removeEventListener('click', toggleMenu);
  }
}

handleMenu();
window.addEventListener('resize', handleMenu);
